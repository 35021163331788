export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const MENU_OPEN = 'MENU_OPEN';
export const SET_MENU = 'SET_MENU';
export const SET_FIT_FOR = 'SET_FIT_FOR';
export const SET_LOADING = 'SET_LOADING';
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const GET_MEN_BASIC_INFO = 'GET_MEN_BASIC_INFO';
export const GET_MEN_STYLE_FIT = 'GET_MEN_STYLE_FIT';
export const GET_MEN_PRICE_RANGE = 'GET_MEN_PRICE_RANGE';
export const GET_MEN_STYLE_CUSTOM = 'GET_MEN_STYLE_CUSTOM';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_ORDER = 'GET_ORDER';
export const GET_ALL_ADDRESS = 'GET_ALL_ADDRESS';
export const GET_SUBJECT = 'GET_SUBJECT';
export const GET_WOMEN_BASIC_INFO = 'GET_WOMEN_BASIC_INFO';
export const GET_WOMEN_STYLE_FIT = 'GET_WOMEN_STYLE_FIT';
export const GET_WOMEN_PRICE_RANGE = 'GET_WOMEN_PRICE_RANGE';
export const GET_WOMEN_STYLE_CUSTOM = 'GET_WOMEN_STYLE_CUSTOM';
export const SET_KID_ORDER = 'SET_KID_ORDER';
export const SET_KID_GENDER = 'SET_KID_GENDER';
export const GET_KIDS_BASIC_INFO = 'GET_KIDS_BASIC_INFO';
export const GET_KIDS_STYLE_FIT = 'GET_KIDS_STYLE_FIT';
export const GET_KIDS_PRICE_RANGE = 'GET_KIDS_PRICE_RANGE';
export const GET_KIDS_STYLE_CUSTOM = 'GET_KIDS_STYLE_CUSTOM';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_INFLUENCER = 'GET_INFLUENCER';
export const UPDATE_CARD_STATUS = 'UPDATE_CARD_STATUS';
export const EDIT_INFLUENCER = 'EDIT_INFLUENCER';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const TOGGLE_EMPLOYEE_ACTIVE = 'TOGGLE_EMPLOYEE_ACTIVE';
export const GET_STATE_SALESTAX = 'GET_STATE_SALESTAX';
export const EDIT_STATE_SALESTAX = 'EDIT_STATE_SALESTAX';
export const GET_SUPPLIER_EMPLOYEE = 'GET_SUPPLIER_EMPLOYEE';
export const GET_SUPPLIER_VENDOR = 'GET_SUPPLIER_VENDOR';
export const GET_SPL_CATEGORY = 'GET_SPL_CATEGORY';
export const GET_SPL_PROD_CATEGORY = 'GET_SPL_PROD_CATEGORY';
export const GET_SPL_PRODUCTS = 'GET_SPL_PRODUCTS';
export const GET_SPL_PROD_USED_DETAILS = 'GET_SPL_PROD_USED_DETAILS';
export const GET_SPL_PROD_USED_SUMMARY = 'GET_SPL_PROD_USED_SUMMARY';
export const GET_SPL_PROFILE = 'GET_SPL_PROFILE';
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const INV_GET_COUNT = 'INV_GET_COUNT';
export const INV_GET_COLOR = 'INV_GET_COLOR';
export const INV_EDIT_COLOR = 'INV_EDIT_COLOR';
export const INV_GET_BRAND = 'INV_GET_BRAND';
export const INV_EDIT_BRAND = 'INV_EDIT_BRAND';
export const INV_TOGGLE_BRAND = 'INV_TOGGLE_BRAND';
export const INV_EDIT_PRODUCT_CATEGORY = 'INV_EDIT_PRODUCT_CATEGORY';
export const INV_GET_PRODUCT_CATEGORIES = 'INV_GET_PRODUCT_CATEGORIES';
export const INV_GET_PROD_SUB_CATEGORY_LIST = 'INV_GET_PROD_SUB_CATEGORY_LIST';
export const INV_GET_PRODUCT_SUB_CATEGORIES = 'INV_GET_PRODUCT_SUB_CATEGORIES';
export const INV_GET_PRODUCT_LIST = 'INV_GET_PRODUCT_LIST';
export const GET_INV_PROFILE = 'GET_INV_PROFILE';
export const GET_INV_VALUESET = 'GET_INV_VALUESET';
export const GET_INV_EMAILTPL = 'GET_INV_EMAILTPL';
export const EDIT_INV_EMAILTPL = 'EDIT_INV_EMAILTPL';
export const GET_MER_EMPLOYEE = 'GET_MER_EMPLOYEE';
export const GET_MER_BRAND = 'GET_MER_BRAND';
export const GET_MER_PREDICTION = 'GET_MER_PREDICTION';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CMS = 'GET_CMS';
export const GET_SOCIAL_MEDIA = 'GET_SOCIAL_MEDIA';
export const GET_EXCHANGE_PRODUCT = 'GET_EXCHANGE_PRODUCT';
export const GET_DECLINE_PRODUCT = 'GET_DECLINE_PRODUCT';
export const GET_SCAN_PRODUCT = 'GET_SCAN_PRODUCT';
export const GET_PROMOCODE = 'GET_PROMOCODE';
export const GET_PROMO_GIFT = 'GET_PROMO_GIFT';
export const GET_OFFER_PROMOCODE = 'GET_OFFER_PROMOCODE';
export const GET_EMAIL = 'GET_EMAIL';
export const GET_ORDER_CODE = 'GET_ORDER_CODE';
export const GET_GIFTCARD = 'GET_GIFTCARD';
export const GET_GIFTCARD_EMAIL = 'GET_GIFTCARD_EMAIL';
export const GET_GIFTCARD_MAIL = 'GET_GIFTCARD_MAIL';
export const GET_GIFTCARD_PRINT = 'GET_GIFTCARD_PRINT';
export const GET_CAREER = 'GET_CAREER';
export const GET_BLOG_CATEGORY = 'GET_BLOG_CATEGORY';
export const GET_BLOG = 'GET_BLOG';
export const GET_BLOG_TAG = 'GET_BLOG_TAG';
export const GET_NEWS = 'GET_NEWS';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_PAYMENT_REFUND_INFO = 'GET_PAYMENT_REFUND_INFO';
export const GET_PAYMENT_REFUND = 'GET_PAYMENT_REFUND';
export const GET_PAYMENT_REFUND_LIST = 'GET_PAYMENT_REFUND_LIST';
export const GET_PAID_LIST = 'GET_PAID_LIST';
export const GET_NOT_PAID_LIST = 'GET_NOT_PAID_LIST';
export const GET_PREVIEW_WORK_LIST = 'GET_PREVIEW_WORK_LIST';
export const GET_IND_PRODUCT = 'GET_IND_PRODUCT';
export const INV_GET_MANUAL_PRODUCT = 'INV_GET_MANUAL_PRODUCT';
export const INV_GET_REPORT_PRODUCT = 'INV_GET_REPORT_PRODUCT';
export const INV_GET_SUMMARY_PRODUCT = 'INV_GET_SUMMARY_PRODUCT';
export const GET_DEFAULT_PRODUCT = 'GET_DEFAULT_PRODUCT';
export const GET_JUNK_LIST = 'GET_JUNK_LIST';
export const GET_BLOCK_LIST = 'GET_BLOCK_LIST';
export const GET_AUTO_LIST = 'GET_AUTO_LIST';
export const GET_STYLE_LIST = 'GET_STYLE_LIST';
export const GET_STATE_LIST = 'GET_STATE_LIST';
export const GET_BATCH_PROCESS_REPORT_LIST = 'GET_BATCH_PROCESS_REPORT_LIST';
export const GET_BATCH_PROCESS_SUBSCRIPTIOM_LIST = 'GET_BATCH_PROCESS_SUBSCRIPTIOM_LIST';
export const GET_CLIENT_BIRTHDAY_LIST = 'GET_CLIENT_BIRTHDAY_LIST';
export const GET_FINALIZED_SUMMARY_REPORT = 'GET_FINALIZED_SUMMARY_REPORT';
export const GET_FINALIZED_DETAIL_REPORT = 'GET_FINALIZED_DETAIL_REPORT';
export const GET_CHECKOUT_SUMMARY_REPORT = 'GET_CHECKOUT_SUMMARY_REPORT';
export const GET_CHECKOUT_DETAIL_REPORT = 'GET_CHECKOUT_DETAIL_REPORT';
export const GET_NOT_CHECKOUT_SUMMARY_REPORT = 'GET_NOT_CHECKOUT_SUMMARY_REPORT';
export const GET_NOT_CHECKOUT_DETAIL_REPORT = 'GET_NOT_CHECKOUT_DETAIL_REPORT';
export const GET_DECLINE_DETAIL_REPORT = 'GET_DECLINE_DETAIL_REPORT';
export const GET_NOT_RETURN_DETAIL_REPORT = 'GET_NOT_RETURN_DETAIL_REPORT';
export const GET_NOT_RTEX_DETAIL_REPORT = 'GET_NOT_RTEX_DETAIL_REPORT';
export const GET_RETURN_PROCESS_DETAIL_REPORT = 'GET_RETURN_PROCESS_DETAIL_REPORT';
export const GET_EXCHANGE_DETAIL_REPORT = 'GET_EXCHANGE_DETAIL_REPORT';
export const GET_DECLINE_SUMMARY_REPORT = 'GET_DECLINE_SUMMARY_REPORT';
export const GET_NOT_RETURN_SUMMARY_REPORT = 'GET_NOT_RETURN_SUMMARY_REPORT';
export const GET_NOT_RTEX_SUMMARY_REPORT = 'GET_NOT_RTEX_SUMMARY_REPORT';
export const GET_RETURN_PROCESS_SUMMARY_REPORT = 'GET_RETURN_PROCESS_SUMMARY_REPORT';
export const GET_EXCHANGE_SUMMARY_REPORT = 'GET_EXCHANGE_SUMMARY_REPORT';
export const GET_STYLING_FEE_REPORT = 'GET_STYLING_FEE_REPORT';
export const GET_CHANGE_AUTO_CHECKOUT = 'GET_CHANGE_AUTO_CHECKOUT';
export const GET_CUSTOM_PAID = 'GET_CUSTOM_PAID';
export const GET_PREVIOUS_WORK = 'GET_PREVIOUS_WORK';
export const GET_NOT_CHECKED_OUT_CUSTOMER = 'GET_NOT_CHECKED_OUT_CUSTOMER';
export const GET_RETURN_NOT_PROCESSED = 'GET_RETURN_NOT_PROCESSED';
export const GET_CHECKED_OUT_PRODUCT_DETAIL = 'GET_CHECKED_OUT_PRODUCT_DETAIL';
export const GET_PRODUCT_ASSIGNED_BUT_NOT_FINALIZED = 'GET_PRODUCT_ASSIGNED_BUT_NOT_FINALIZED';
export const GET_LIST_PRODUCT_NOT_RETURNED = 'GET_LIST_PRODUCT_NOT_RETURNED';
export const GET_MONTHLY_SALE = 'GET_MONTHLY_SALE';
export const GET_TOTAL_PRODUCTS_IN = 'GET_TOTAL_PRODUCTS_IN';
export const GET_CHECKED_OUT_NOT_RETURN_DETAIL = 'GET_CHECKED_OUT_NOT_RETURN_DETAIL';
export const GET_PRODUCT_FINALIZE_SUMMARY = 'GET_PRODUCT_FINALIZE_SUMMARY';
export const GET_PRODUCT_FINALIZE_DETAIL = 'GET_PRODUCT_FINALIZE_DETAIL';
export const GET_PRODUCT_DECLINED_SUMMARY = 'GET_PRODUCT_DECLINED_SUMMARY';
export const GET_PRODUCT_DECLINE_DETAIL = 'GET_PRODUCT_DECLINE_DETAIL';
export const GET_NOT_CHECKOUT_SUMMARY = 'GET_NOT_CHECKOUT_SUMMARY';
export const GET_NOT_CHECKOUT_DETAIL = 'GET_NOT_CHECKOUT_DETAIL';
export const GET_MONTHLY_CLIENT_CONSUMED = 'GET_MONTHLY_CLIENT_CONSUMED';
export const GET_MONTHLY_PRODUCT_NOT_RETURNED = 'GET_MONTHLY_PRODUCT_NOT_RETURNED';
export const GET_MONTHLY_PRODUCT_DECLINED = 'GET_MONTHLY_PRODUCT_DECLINED';
export const GET_MONTHLY_LOSS = 'GET_MONTHLY_LOSS';
export const GET_CLINET_CHECK_OUT_SUMMARY = 'GET_CLINET_CHECK_OUT_SUMMARY';
export const GET_CLINET_CHECK_OUT_DETAIL = 'GET_CLINET_CHECK_OUT_DETAIL';
export const GET_CHECK_OUT_RETURN_SUMMARY = 'GET_CHECK_OUT_RETURN_SUMMARY';
export const GET_CHECK_OUT_RETURN_DETAIL = 'GET_CHECK_OUT_RETURN_DETAIL';
export const GET_CHECK_OUT_NOT_RETURN_SUMMARY = 'GET_CHECK_OUT_NOT_RETURN_SUMMARY';
export const GET_MONTHLY_REVENUE = 'GET_MONTHLY_REVENUE';
export const USER_PROFILE = 'USER_PROFILE';
export const GET_MATCHING_LIST = 'GET_MATCHING_LIST';
export const GET_BROSWER_LIST = 'GET_BROSWER_LIST';
export const GET_INITIAL = 'GET_INITIAL';
export const GET_CLIENT_PRODUCTS = 'GET CLIENT PRODUCTS';
export const SET_SHIPPING_ADDRESS_ID = 'SET SHIPPING ADDRESS ID';
