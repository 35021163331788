import { GET_PAYMENT_METHODS, UPDATE_CARD_STATUS, SHOW_POPUP, HIDE_POPUP } from 'actions/common/types';

const initialState = {
  loading: true,
  payMethods: [],
  popupVisible: false,
  transactionId: null
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        payMethods: payload.methods,
        customerId: payload.customerId,
        loading: false
      };

    case UPDATE_CARD_STATUS:
      return {
        ...state,
        payMethods: state.payMethods.map((method) =>
          method.id === payload.selectedCardId ? { ...method, use_card: 1 } : { ...method, use_card: 0 }
        )
      };
    case SHOW_POPUP:
      return {
        ...state,
        popupVisible: true,
        transactionId: payload.transactionId
      };
    case HIDE_POPUP:
      return {
        ...state,
        popupVisible: false,
        transactionId: null
      };

    default:
      return state;
  }
};

export default paymentReducer;
