import { setAlert } from 'actions/common/alert';
import { loadUser } from 'actions/common/auth';
import {
  GET_MEN_BASIC_INFO,
  GET_MEN_PRICE_RANGE,
  GET_MEN_STYLE_FIT,
  GET_MEN_STYLE_CUSTOM,
  GET_WOMEN_BASIC_INFO,
  GET_WOMEN_STYLE_FIT,
  GET_WOMEN_PRICE_RANGE,
  GET_WOMEN_STYLE_CUSTOM,
  GET_SCHEDULE,
  GET_PROMOCODE,
  GET_PROMO_GIFT,
  GET_ORDER_CODE,
  GET_ORDER,
  GET_ALL_ADDRESS,
  GET_SUBJECT,
  GET_CLIENT_PRODUCTS
} from 'actions/common/types';
import Api from 'utils/Api';
import { ErrorHandler } from 'utils/ErrorHandler';
import DFnewLogger from 'utils/DFnewLogger';

//========================= Select FIT =========================//
export const selectFit = (values, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/user/selectfit', values);
    if (res.data === 'men' || res.data === 'women') {
      setAlert('Your FIT is selected', 'success');
      dispatch(loadUser());
      let fitFor = res.data === 'women' ? 2 : 1;
      localStorage.setItem('fitFor', fitFor);
      navigate(`/welcome/basic-info/${res.data}`);
    } else {
      setAlert('Please select again', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

//============================= Men =============================//
export const mEditBasicInfo = (formData, navigate) => async (dispatch) => {
  try {
    console.log(formData);
    const res = await Api.post('/fitprofile/basicinfo/men/edit', formData);
    if (res.data === 'Basic Info of Men Fit Profile has been saved') {
      if (navigate) {
        navigate('/welcome/style-fit/men');
      }
      dispatch(loadUser());
      setAlert('Basic Info is successfully saved', 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const mGetBasicInfo = () => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/basicinfo/men');
    dispatch({
      type: GET_MEN_BASIC_INFO,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const mEditStyleFit = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/stylefit/men/edit', formData);
    if (res.data === 'Style Fit of Men Fit Profile has been saved') {
      dispatch(loadUser());
      setAlert('Style Fit is successfully saved', 'success');
      if (navigate) {
        navigate('/welcome/price-range/men');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const mGetStyleFit = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/stylefit/men', data);
    dispatch({
      type: GET_MEN_STYLE_FIT,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const mEditPriceRange = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/pricerange/men/edit', formData);
    if (res.data === 'Price Range of Men Fit Profile has been saved') {
      dispatch(loadUser());
      setAlert('Price Range is successfully saved', 'success');
      if (navigate) {
        navigate('/welcome/style-custom/men');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const mGetPriceRange = () => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/pricerange/men');
    dispatch({
      type: GET_MEN_PRICE_RANGE,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const mEditStyleCustom = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/customdsgnbrand/men/edit', formData);
    if (res.data) {
      dispatch(loadUser());
      setAlert('Custom Style is successfully saved', 'success');
      if (navigate) {
        switch (res.data.is_redirect) {
          case 0:
            navigate('/welcome/style/');
            break;
          case 1:
            navigate('/welcome/schedule');
            break;
          case 2:
            navigate('/not-yet-shipped');
            break;
          case 3:
            navigate('/profile-review');
            break;
          case 4:
            navigate('/order-review');
            break;
          case 5:
            navigate('/calendar-sechedule');
            break;
          case 6:
            navigate('/customer-order-review');
            break;
          default:
            navigate('/default-path');
            break;
        }
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    //ErrorHandler(err);
  }
};

export const mGetStyleCustom = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/customdsgnbrand/men', data);
    dispatch({
      type: GET_MEN_STYLE_CUSTOM,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

//============================= Women =============================//
export const wEditBasicInfo = (formData, navigate) => async (dispatch) => {
  try {
    console.log('editBasicInfo');
    const res = await Api.post('/fitprofile/basicinfo/women/edit', formData);
    if (res.data === 'Basic Info of Women Fit Profile has been saved') {
      dispatch(loadUser());
      setAlert('Basic Info is successfully saved', 'success');
      if (navigate) {
        navigate('/welcome/style-fit/women');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wGetBasicInfo = () => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/basicinfo/women');
    dispatch({
      type: GET_WOMEN_BASIC_INFO,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wEditStyleFit = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/stylefit/women/edit', formData);
    if (res.data === 'Style Fit of Women Fit Profile has been saved') {
      dispatch(loadUser());
      setAlert('Style Fit is successfully saved', 'success');
      if (navigate) {
        navigate('/welcome/price-range/women');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wGetStyleFit = () => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/stylefit/women');
    dispatch({
      type: GET_WOMEN_STYLE_FIT,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wEditPriceRange = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/pricerange/women/edit', formData);
    if (res.data === 'Price Range of Women Fit Profile has been saved') {
      dispatch(loadUser());
      setAlert('Price Range is successfully saved', 'success');
      if (navigate) {
        navigate('/welcome/style-custom/women');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wGetPriceRange = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/pricerange/women', data);
    dispatch({
      type: GET_WOMEN_PRICE_RANGE,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wEditStyleCustom = (formData, navigate) => async (dispatch) => {
  try {
    console.log('formdata:', formData);
    const res = await Api.post('/fitprofile/customdsgnbrand/women/edit', formData);
    if (res.data.message === 'Custom Design & Brands of Women Fit Profile has been saved') {
      dispatch(loadUser());
      setAlert('Custom Style is successfully saved', 'success');
      // Handle redirection based on is_redirect value
      switch (res.data.is_redirect) {
        case 0:
          navigate('/welcome/style/');
          break;
        case 1:
          navigate('/welcome/schedule');
          break;
        case 2:
          navigate('/not-yet-shipped');
          break;
        case 3:
          navigate('/profile-review/');
          break;
        case 4:
          navigate('/order-review');
          break;
        case 5:
          navigate('/calendar-sechedule');
          break;
        case 6:
          navigate('/customer-order-review');
          break;
        default:
          navigate('/default-path');
          break;
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const wGetStyleCustom = () => async (dispatch) => {
  try {
    const res = await Api.post('/fitprofile/customdsgnbrand/women');
    dispatch({
      type: GET_WOMEN_STYLE_CUSTOM,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const editSchedule = (data, navigate) => async (dispatch) => {
  try {
    console.log('scheduledata:', data);
    const res = await Api.post('/setting/schedule/edit', data);

    if (res) {
      if (navigate) {
        // Check the value of currentLocation
        if (data.currentLocation === '/account') {
          navigate('/account');
          setAlert('Schedule is successfully saved', 'success');
          if (data.kid_id !== 0) {
            dispatch(getSchedule(data.fitFor, data.kid_id));
          } else {
            dispatch(getSchedule());
          }
        } else {
          navigate('/welcome/reservation');
          dispatch(getSchedule());
        }
      } else {
        dispatch(getSchedule());
        setAlert('Schedule is successfully saved', 'success');
      }
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const getSchedule = (fitFor, kidId) => async (dispatch) => {
  try {
    // Initialize params based on fitFor
    const params = fitFor >= 3 ? { kidId } : {};
    const res = await Api.get('/setting/schedule', {
      params, // Pass the params (will be empty if fitFor < 3)
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    dispatch({
      type: GET_SCHEDULE,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const addAddress = (data) => async (dispatch) => {
  try {
    let res = await Api.post('/setting/shipaddress/add', data);
    if (res) {
      dispatch(getAllAddress(data));
      setAlert('Success', 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const getAllAddress = () => async (dispatch) => {
  try {
    const res = await Api.get('/setting/shipaddress/all');
    if (res) {
      dispatch({
        type: GET_ALL_ADDRESS,
        payload: res.data
      });
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const getSubject = () => async (dispatch) => {
  try {
    const res = await Api.get('/setting/subject');
    if (res) {
      dispatch({
        type: GET_SUBJECT,
        payload: res.data
      });
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const deleteAddress = (data) => async (dispatch) => {
  try {
    const res = await Api.post(`/setting/shipaddress/delete`, data);
    if (res.data) {
      dispatch(getAllAddress());
      setAlert('Success', 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const editAddress = (data) => async (dispatch) => {
  try {
    let res = await Api.post(`/setting/shipaddress/edit`, data);
    if (res) {
      dispatch(getAllAddress(data));
    }
    setAlert('Success', 'success');
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const deliverAddress = (data) => async (dispatch) => {
  try {
    let res = await Api.post(`/setting/shipaddress/deliver`, data);
    if (res) {
      dispatch(getAllAddress(data));
    }
    setAlert('Success', 'success');
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const defaultAddress = (data) => async (dispatch) => {
  try {
    let res = await Api.post(`/setting/shipaddress/default`, data);
    if (res) {
      dispatch(getAllAddress(data));
    }
    setAlert('Success', 'success');
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const getUserProducts =
  (kidId = 0) =>
  async (dispatch) => {
    try {
      // Prepare the parameters
      const params = {};
      if (kidId) {
        params.kid_id = kidId;
      }
      const res = await Api.get('/user/getProducts', {
        params,
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
      console.log('body req:', res.data);
      return dispatch({
        type: GET_CLIENT_PRODUCTS,
        payload: res.data
      });
    } catch (err) {
      DFnewLogger(err?.message);
      ErrorHandler(err);
    }
  };

export const reportOrderReview = (products, productStatus, formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/user/orderReview', { products, productStatus, formData });
    if (res.data && res.data.length > 0) {
      const kidId = res.data[0].kid_id;
      console.log('kidId:', kidId);
      dispatch(getUserProducts(kidId));
    } else {
      dispatch(getUserProducts());
    }
    navigate('/customer-order-review');
  } catch (error) {
    console.error('API error:', error?.message);
    DFnewLogger(error?.message);
    ErrorHandler(error);
  }
};

export const getTaxRate = async (zipcode) => {
  try {
    const res = await Api.get('/user/tax', { params: { zipcode } });
    return res.data.tax;
  } catch (error) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const redeemPromocode = (data, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/setting/promocode/add', data);
    console.log('API response:', res);
    if (res.status === 200) {
      dispatch(getPromocode());
      return { success: true, message: res.data.message };
    } else {
      return { success: false, message: res.data.message || 'Failed to apply promo code' };
    }
  } catch (err) {
    const errorMsg = err.response?.data?.message || 'Internal Server Error';
    return { success: false, message: errorMsg };
  }
};
export const getPromocode = () => async (dispatch) => {
  try {
    const res = await Api.get('/setting/promocode', {
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    console.log('body promo:', res);
    dispatch({
      type: GET_PROMOCODE,
      payload: res.data.msg
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const getOrdercode = (paymentID) => async (dispatch) => {
  try {
    const res = await Api.get('/setting/ordercode', {
      params: { paymentID },
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    dispatch({
      type: GET_ORDER_CODE,
      payload: res.data
    });
    return res.data; // Return data if needed
  } catch (err) {
    console.error(err);
    return null;
  }
};
export const redeemGiftCode = (data, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/setting/giftcode/add', data);
    console.log('API response:', res);
    if (res.status === 200) {
      dispatch(getPromocode());
      return { success: true, message: res.data.message };
    } else {
      return { success: false, message: res.data.message || 'Failed to apply gift code' };
    }
  } catch (err) {
    const errorMsg = err.response?.data?.message || 'Internal Server Error';
    return { success: false, message: errorMsg };
  }
};
export const addShippingAddress = (data, navigate) => async (dispatch) => {
  try {
    console.log('navigate:', data);
    const res = await Api.post('/setting/shipping/edit', data);
    console.log('response:', res);
    if (res.status === 200) {
      setAlert('Reservation date is successfully saved', 'success');
      navigate('/welcome/addressbook');
    } else {
      setAlert('Failed to save Reservation address', 'error');
    }
  } catch (err) {
    console.log('Error msggg:', err);
    setAlert('An error occurred while saving the Reservation date', 'error');
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const applyPromoCode = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/setting/promogift/add', data);
    if (res.data.success) {
      dispatch(getOrdercode(data.paymentID));
      const { price, msg } = res.data;
      return { success: true, price, message: msg || `Your account has been credited $${price}` };
    } else {
      return { success: false, message: res.data.msg || 'Failed to apply promo code' };
    }
  } catch (err) {
    const errorMsg = err.response?.data?.msg || 'Internal Server Error';
    return { success: false, message: errorMsg };
  }
};
export const checkCodeAvailability = (data) => async (dispatch) => {
  try {
    const res = await Api.post('/setting/checkcode/add', data);
    console.log('respond:', res);
    if (res.status === 200) {
      const { price, msg, status } = res.data; // Destructure price, msg, and status
      // Check if the backend returned an error status
      if (status === 'error') {
        return { success: false, message: msg }; // Treat it as failure if status is 'error'
      } else {
        return { success: true, message: msg, price }; // Otherwise, success
      }
    } else {
      return { success: false, message: res.data.message || 'Failed to apply promo code' };
    }
  } catch (err) {
    const errorMsg = err.response?.data?.message || 'Internal Server Error';
    return { success: false, message: errorMsg };
  }
};
export const getOrders = (fitFor, kidId) => async (dispatch) => {
  try {
    const params = fitFor >= 3 ? { kidId } : {};
    const res = await Api.get('/setting/order', {
      params,
      headers: {
        'Cache-Control': 'no-cache'
      }
    });
    console.log('respondata:', res.data);
    if (res.data.userDetails?.is_redirect === 6 || res.data.kidDetails?.is_redirect === 6) {
      return { redirect: true };
    }
    dispatch({
      type: GET_ORDER,
      payload: res.data
    });
    return { redirect: false };
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
    return { redirect: false };
  }
};
