import { combineReducers } from 'redux';
import customization from './customization';
//-- Customer

import profile from './client/profile';
import kids from './client/kids';
import payment from './payment';
//-- Admin
import influencer from './admin/influencer';
import stateSalesTax from './admin/stateSalesTax';
import customer from './admin/customer';
import cms from './admin/cms';
import socialMedia from './admin/socialMedia';
import product from './admin/product';
import promocode from './admin/promocode';
import offerPromocode from './admin/offerPromocode';
import giftcard from './admin/giftcard';
import career from './admin/career';
import blog from './admin/blog';
import news from './admin/news';
import subscription from './admin/subscription';
import report from './admin/report';
import initial from './admin/initial';

//-- Merchandise
import merBrand from './merBrand';
import prediction from './merPrediction';
//--common
import employee from './common/employee';
import alert from './common/alert';
import auth from './common/auth';
import masterSummary from './admin/masterSummary';
import masterSupport from './admin/masterSupport';

export default combineReducers({
  alert,
  auth,
  customization,
  profile,
  kids,
  payment,
  influencer,
  stateSalesTax,
  employee,
  merBrand,
  prediction,
  customer,
  cms,
  socialMedia,
  product,
  promocode,
  offerPromocode,
  giftcard,
  career,
  blog,
  news,
  subscription,
  report,
  masterSummary,
  masterSupport,
  initial
});
