import MyEnvConfig from 'configs/MyEnvConfig';

const GenS3Link = (path) => {
  try {
    if (process.env.NODE_ENV === 'production') {
      // If in production, return the S3 link
      //return `https://${MyEnvConfig.aws.s3Bucket}.s3.${MyEnvConfig.aws.region}.amazonaws.com/${path}`;
      return window.location.origin + `/images/${path}`;
    } else {
      // If in development, return local URL
      return window.location.origin + `/images/${path}`;
    }
  } catch (e) {
    // Log any errors and return a placeholder image URL
    console.error(e?.message);
    return 'https://via.placeholder.com/518';
  }
};

export default GenS3Link;
