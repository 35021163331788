import { setAlert } from 'actions/common/alert';
import { loadUser } from 'actions/common/auth';
import { GET_PAYMENT_METHODS, SET_LOADING, UPDATE_CARD_STATUS, SHOW_POPUP, HIDE_POPUP } from 'actions/common/types';
import Api from 'utils/Api';
import { ErrorHandler } from 'utils/ErrorHandler';
import DFnewLogger from 'utils/DFnewLogger';
import { getUserProducts } from './client/profile';

export const attachPaymentMethod = async (formData, navigate) => {
  try {
    DFnewLogger(formData);
    const res = await Api.post('/payment/stripe/paymethod/attach', formData);
    if (res.data === 'Payment method attached successfully') {
      navigate('/welcome/payment');
      setAlert(res.data, 'success');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const createCustomerSecretMethod = async () => {
  try {
    const res = await Api.post('/payment/stripe/customer/create');
    return res.data;
  } catch (error) {
    DFnewLogger(error?.message);
    ErrorHandler(error);
  }
};

export const getPaymentMethods = () => async (dispatch) => {
  try {
    const res = await Api.get('/payment/stripe/paymethods/list');
    console.log('response:', res);
    dispatch({
      type: GET_PAYMENT_METHODS,
      payload: {
        methods: res.data.methods,
        customerId: res.data.customerId
      }
    });
  } catch (err) {
    // DFnewLogger(err?.message);
    // ErrorHandler(err);
    // if (err.res?.status === 400) {
    //   navigate('/welcome/addressbook');
    // } else {
    //   dispatch(setAlert('An error occurred while fetching payment methods', 'error'));
    // }
  }
};
export const updateCardStatus = (selectedCardId, userId) => async (dispatch) => {
  try {
    await Api.post('/payment/stripe/card/status', {
      selectedCardId,
      userId
    });

    dispatch({
      type: UPDATE_CARD_STATUS,
      payload: {
        selectedCardId,
        userId
      }
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const addCardDetails = (setupIntent) => async (dispatch) => {
  try {
    await Api.post('/payment/stripe/card/add', { setupIntent });
    dispatch(getPaymentMethods());
  } catch (err) {
    //DFnewLogger(err?.message);
    //ErrorHandler(err);
  }
};

export const createPayIntentOfStyleFee = (data, navigate) => async (dispatch) => {
  try {
    DFnewLogger(new Date());
    DFnewLogger(data);
    dispatch({ type: SET_LOADING, payload: true }); // Start loading
    const res = await Api.post('/payment/stripe/payintent/stylingfee/create', data);
    if (res.data.status === 'requires_action') {
      dispatch({ type: SET_LOADING, payload: false }); // Stop loading
      navigate('/authentication-confirm', {
        state: { clientSecret: res.data.client_secret, paymentMethod: data.paymentMethod }
      });
    } else if (res.data.status === 'succeeded') {
      dispatch({ type: SET_LOADING, payload: false }); // Stop loading
      dispatch({ type: SHOW_POPUP, payload: { transactionId: res.data.charges.data[0].balance_transaction } });
      setTimeout(() => {
        navigate('/payment-success'); // Redirect after popup display
        dispatch({ type: HIDE_POPUP }); // Hide popup
      }, 3000);
      dispatch(getUserProducts());
    } else {
      setAlert('ACTION_createPayIntentOfStyleFee_ERROR', 'error');
      dispatch({ type: SET_LOADING, payload: false }); // Stop loading on failure
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
    dispatch({ type: SET_LOADING, payload: false }); // Stop loading on error
  }
};

export const confirmPayIntent = (paymentIntent, paymentMethod, navigate) => async (dispatch) => {
  try {
    const res = await Api.post('/payment/stripe/payintent/confirm', { paymentIntent, paymentMethod });
    console.log('[confirm]: ', res.data);
    dispatch({ type: SET_LOADING, payload: true });
    if (res.data.status === 'succeeded') {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SHOW_POPUP, payload: { transactionId: res.data.charges.data[0].balance_transaction } });
      setTimeout(() => {
        navigate('/payment-success'); // Redirect after popup display
        dispatch({ type: HIDE_POPUP }); // Hide popup
      }, 3000);
      dispatch(getUserProducts());
    } else if (res.data.status === 'requires_action') {
      // Handle if requires further action
      navigate('/authentication-confirm', { state: { paymentIntent: res.data.id, paymentMethod: data.paymentMethod } });
    } else {
      // Handle other possible statuses
      setAlert('ACTION_confirmPayIntent_ERROR', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const addMenBillingInfo = (data) => async (dispatch) => {
  try {
    DFnewLogger(data);
    const res = await Api.post('/fitprofile/billinginfo/men/add', data);
    if (res) {
      setAlert('Thank You For Choosing Drape Fit', 'success');
      dispatch(loadUser());
    } else {
      setAlert('ACTION_addMenBillingInfo_ERROR', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const addWomenBillingInfo = (data) => async (dispatch) => {
  try {
    DFnewLogger(data);
    const res = await Api.post('/fitprofile/billinginfo/women/add', data);
    if (res) {
      setAlert('Thank You For Choosing Drape Fit', 'success');
      dispatch(loadUser());
    } else {
      setAlert('ACTION_addWomenBillingInfo_ERROR', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const addGirlBillingInfo = (data, order) => async (dispatch) => {
  try {
    DFnewLogger(data);
    const res = await Api.post(`/kidsprofile/billinginfo/girl/${order}/add`, data);
    if (res) {
      setAlert('Thank You For Choosing Drape Fit', 'success');
      dispatch(loadUser());
    } else {
      setAlert('ACTION_addGirlBillingInfo_ERROR', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const addBoyBillingInfo = (data, order) => async (dispatch) => {
  try {
    DFnewLogger(data);
    const res = await Api.post(`/kidsprofile/billinginfo/boy/${order}/add`, data);
    if (res) {
      setAlert('Thank You For Choosing Drape Fit', 'success');
      dispatch(loadUser());
    } else {
      setAlert('ACTION_addBoyBillingInfo_ERROR', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
export const payForProducts = (data, navigate) => async (dispatch) => {
  try {
    console.log('datfull:', data);
    dispatch({ type: SET_LOADING, payload: true });
    const res = await Api.post('/payment/stripe/pay/products', data);
    if (res.data.status === 'requires_action') {
      dispatch({ type: SET_LOADING, payload: false });
      navigate('/authentication-confirm', {
        state: { clientSecret: res.data.client_secret, paymentMethod: data.paymentMethod }
      });
    } else if (res.data.status === 'succeeded') {
      dispatch({ type: SET_LOADING, payload: false });
      navigate('/payment-success');
      dispatch(getUserProducts());
    } else {
      setAlert('ACTION_createPayIntentOfStyleFee_ERROR', 'error');
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
    dispatch({ type: SET_LOADING, payload: false });
  }
};
