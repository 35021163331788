import { setAlert } from 'actions/common/alert';
import { loadUser } from 'actions/common/auth';
import {
  GET_KIDS_BASIC_INFO,
  GET_KIDS_STYLE_FIT,
  GET_KIDS_PRICE_RANGE,
  GET_KIDS_STYLE_CUSTOM
} from 'actions/common/types';
import Api from 'utils/Api';
import { ErrorHandler } from 'utils/ErrorHandler';
import DFnewLogger from 'utils/DFnewLogger';

export const addNewKid = (order, navigate) => async (dispatch) => {
  try {
    const existingKidId = localStorage.getItem(`kid_${order}_id`);
    if (existingKidId) {
      setAlert('This child has already been added', 'warning');
      return;
    }
    const res = await Api.post(`/user/kids/add/${order}`);
    const { ordinal, id } = res.data;
    setAlert(`YOUR ${ordinal} CHILD has been added`, 'success');
    dispatch(loadUser());
    localStorage.setItem('order', order);
    localStorage.setItem('fitFor', 3);
    localStorage.setItem(`kid_${order}_id`, id);
    navigate(`/welcome/basic-info/kids`);
  } catch (err) {
    if (err?.response?.data?.msg === 'You can add up to 4 children') {
      setAlert('You can add up to 4 children', 'warning');
    }
    DFnewLogger(err?.message);
    console.error('Error caught:', err);
  }
};
export const kEditBasicInfo = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/basicinfo/edit`, formData);
    if (res.data === 'Basic Info of Kid Fit Profile has been saved') {
      if (navigate) {
        const fitForValue = formData.kids_clothing_gender === `Boy's Product` ? 3 : 4;
        localStorage.setItem('fitFor', fitForValue);
        navigate(`/welcome/style-fit/kids/${fitForValue === 3 ? 'boys' : 'girls'}`);
      }
      setAlert('Basic Info is successfully saved', 'success');
      dispatch(loadUser());
    }
  } catch (err) {
    DFnewLogger(err?.message);
    CatchError(err);
  }
};

export const kGetBasicInfo = (data) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/basicinfo`, data);
    dispatch({
      type: GET_KIDS_BASIC_INFO,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const kEditStyleFit = (formData, navigate) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/stylefit/edit`, formData);
    if (res.data === 'Style Fit of Kid Fit Profile has been saved') {
      if (navigate) {
        let fitFor = localStorage.getItem('fitFor');
        if (Number(fitFor) === 3) {
          navigate('/welcome/price-range/kids/boys');
        } else {
          navigate('/welcome/price-range/kids/girls');
        }
      }
      setAlert('Style Fit is successfully saved', 'success');
      dispatch(loadUser());
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const kGetStyleFit = (data) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/stylefit`, data);
    dispatch({
      type: GET_KIDS_STYLE_FIT,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const kEditPriceRange = (formData, kid_count, navigate) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/pricerange/kid/edit`, { ...formData, kid_count });
    if (res.data === 'Price Range of Kid Fit Profile has been saved') {
      if (navigate) {
        let fitFor = localStorage.getItem('fitFor');
        if (Number(fitFor) === 3) {
          navigate('/welcome/style-custom/kids/boys');
        } else {
          navigate('/welcome/style-custom/kids/girls');
        }
      }
      setAlert('Price Range is successfully saved', 'success');
      dispatch(loadUser());
    }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const kGetPriceRange = (data) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/pricerange/kid`, data);
    dispatch({
      type: GET_KIDS_PRICE_RANGE,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const kGetStyleCustom = (data) => async (dispatch) => {
  try {
    const res = await Api.post(`/kidsprofile/customdsgnbrand/kid`, data);
    if (res.data.kid_id) {
      const localStorageKey = `kid_${data.kid_count}_id`;
      localStorage.setItem(localStorageKey, res.data.kid_id);
      console.log(`Stored kid_id in local storage with key ${localStorageKey}:`, res.data.kid_id);
    }
    dispatch({
      type: GET_KIDS_STYLE_CUSTOM,
      payload: res.data
    });
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};

export const kEditStyleCustom = (formData, kid_count, navigate) => async (dispatch) => {
  console.log('FormData:', formData);
  try {
    const res = await Api.post(`/kidsprofile/customdsgnbrand/kid/edit`, { ...formData, kid_count });
    if (res.data) {
      dispatch(loadUser());
      setAlert('Custom Design & Brands of Kid Fit Profile has been saved', 'success');
      if (navigate) {
        switch (res.data.is_redirect) {
          case 1:
            navigate('/welcome/schedule', {
              state: {
                user_id: formData.user_id,
                kid_id: formData.kid_id,
                brands: formData.brands,
                profile_note: formData.profile_note
              }
            });
            break;
          case 2:
            navigate('/not-yet-shipped');
            break;
          case 3:
            navigate('/profile-review');
            break;
          case 4:
            navigate('/order-review');
            break;
          case 5:
            navigate('/calendar-sechedule');
            break;
          case 6:
            navigate('/customer-order-review');
            break;
          default:
            navigate('/default-path');
            break;
        }
      }
    }
    // if (res.data === 'Custom Design & Brands of Kid Fit Profile has been saved and emails sent successfully') {
    //   if (navigate) {
    //     console.log('Navigating to /welcome/schedule with state:', {
    //       user_id: formData.user_id,
    //       kid_id: formData.kid_id,
    //       brands: formData.brands,
    //       profile_note: formData.profile_note
    //     });
    //     navigate('/welcome/schedule', {
    //       state: {
    //         user_id: formData.user_id,
    //         kid_id: formData.kid_id,
    //         brands: formData.brands,
    //         profile_note: formData.profile_note
    //       }
    //     });
    //   }
    //   setAlert('Custom Design & Brands is saved', 'success');
    //   dispatch(loadUser());
    // }
  } catch (err) {
    DFnewLogger(err?.message);
    ErrorHandler(err);
  }
};
