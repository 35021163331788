export default {
  baseurl: {
    dev: '127.0.0.1',
    prod: 'http://104.131.0.179:5180/api'
  },
  stripe: {
    pbKey: 'pk_test_51JY90jITPrbxGSMcuo8bhxqQhCbSvHghLQaYIxtqVSe9u2xxm80SDtIVQ9acsLTW4WyPJX5G0nIMxaLXwtXbsN0N00vkBYmYDU'
  },
  bearer: {
    tokenPrefix: 'DrapeFit_New'
  },
  gcaptcha: {
    siteKey: '6LdcbC8qAAAAANkVnZa0u9A5nTVbWC7zIbkVYfSr'
  },
  aws: {
    region: 'ap-south-1',
    s3Bucket: 'new-drapefit-bucket'
  }
};
