import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Adjust path as per your project structure
import SetAuthToken from './utils/SetAuthToken'; // Adjust path as per your project structure

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production' // Enable Redux DevTools only in development
});

// Listen to store changes to update authentication token
let currentState = store.getState();
store.subscribe(() => {
  const previousState = currentState;
  currentState = store.getState();

  // Check if auth token has changed
  if (previousState.auth.token !== currentState.auth.token) {
    const token = currentState.auth.token;
    const isRememberMe = currentState.auth.isRememberMe;
    console.log('Token changed:', token, 'Remember Me:', isRememberMe);
    SetAuthToken(token, isRememberMe); // Update authentication token
  }
});

export default store;
